import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_ROOT = null;
const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD = [0];

export type IntersectionObserverHookRefCallbackNode = Element | null;

export type IntersectionObserverHookRefCallback = (
  node: IntersectionObserverHookRefCallbackNode
) => void;

export type IntersectionObserverHookResult = [
  IntersectionObserverHookRefCallback,
  { entry: IntersectionObserverEntry | undefined }
];

export const useIntersectionObserver = (
  ref,
  {
    threshold = DEFAULT_THRESHOLD,
    root = DEFAULT_ROOT,
    rootMargin = DEFAULT_ROOT_MARGIN,
  }
) => {
  // configure the state
  const [state, setState] = useState({
    inView: false,
    triggered: false,
    entry: undefined,
  });

  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries, observerInstance) => {
        if (entries[0].intersectionRatio > 0) {
          setState({
            inView: true,
            triggered: true,
            entry: observerInstance,
          });
        } else {
          setState({
            inView: false,
            triggered: true,
            entry: observerInstance,
          });
        }
        // return;
      },
      {
        threshold,
        root,
        rootMargin,
      }
    );

    const { current: currentObserver } = observer;

    if (ref.current && !state.triggered) {
      currentObserver.observe(ref.current);
    }
    return () => currentObserver.disconnect();
  }, [threshold, root, rootMargin, ref]);

  return [state.inView, state.entry];
};

export default useIntersectionObserver;
