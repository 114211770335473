import { config, useSpring } from 'react-spring';

export function useScrollTo() {
  const [y, setScrollPos, stopScroll] = useSpring(() => ({
    immediate: false,
    y: 0,
    onChange: (y) => {
      window.scrollTo(0, y);
    },
    onRest: () => {
      window.removeEventListener('wheel', stopScrollOnWheel);
    },
    config: config.slow,
  }));

  const scrollTo = ({ current }) => {
    window.addEventListener('wheel', stopScrollOnWheel);
    setScrollPos({
      y:
        current.getBoundingClientRect().top >= 0
          ? current.getBoundingClientRect().top + window.pageYOffset
          : window.pageYOffset + current.getBoundingClientRect().top,
    });
  };

  function stopScrollOnWheel() {
    window.removeEventListener('wheel', stopScrollOnWheel);
    stopScroll();
  }

  return scrollTo;
}

export default useScrollTo;
