import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export function useScrollLock(enabled = false) {
  const [scrollLock, setScrollLock] = useState<boolean>(false);
  // const [ref, setRef] = useState<null | HTMLElement>(null);
  const ref = useRef(null);

  useEffect(() => {
    if (enabled || scrollLock) {
      disableBodyScroll(ref.current);
    } else if (ref.current && !enabled) {
      enableBodyScroll(ref.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [scrollLock, ref.current, enabled]);

  // return [setScrollLock, setRef];
  return [setScrollLock, ref];
}

export default useScrollLock;
